<template>
  <div>
    <!-- 新增File -->
    <el-dialog
      :close-on-click-modal="false"
      :title="`Add New`"
      :visible.sync="addFileShow"
      width="70%"
      center
    >
      <div class="container">
        <el-form
          ref="form"
          :model="FileInfo"
          :rules="formRules"
          label-width="90px"
        >
          <el-form-item :label="`Type`" prop="newType" required>
            <el-radio-group v-model="FileInfo.newType">
              <el-radio :label="`folder`">
                <i class="fas fa-folder"></i>
                Folder
              </el-radio>
              <el-radio :label="`document`">
                <i class="fas fa-file"></i>
                Materials
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            :label="$t('newCourses.Materials')"
            v-if="FileInfo.newType === 'document'"
            prop="type"
            required
          >
            <div>
              <el-tabs
                type="border-card"
                style="width: 100%;"
                v-model="FileInfo.type"
              >
                <!-- File -->
                <el-tab-pane label="file" name="document">
                  <span slot="label">
                    <i class="fas fa-file"></i>
                    {{ $t("newCourses.File") }}
                  </span>
                  <el-upload
                    v-loading="loading"
                    element-loading-text="Uploading..."
                    element-loading-spinner="el-icon-loading"
                    v-show="FileInfo.type === 'document'"
                    class="upload-demo"
                    drag
                    name="file"
                    :action="postUrl"
                    :headers="headers"
                    :show-file-list="true"
                    :before-upload="beforeUpload"
                    :on-success="handleUploadSuccess"
                    :limit="1"
                    :on-error="errorUpload"
                    :on-exceed="overLimit"
                  >
                    <div
                      slot="tip"
                      class="el-upload__tip m-0"
                      style="line-height: 1rem;"
                    >
                      <b>{{
                        $t("newCourses.File cannot be bigger than 20 mb")
                      }}</b>
                    </div>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      {{ $t("newCourses.Drag file here or upload") }}
                    </div>
                  </el-upload>
                </el-tab-pane>
                <!-- Url -->
                <el-tab-pane label="link" name="link">
                  <span slot="label">
                    <i class="fas fa-link"></i>
                    {{ $t("newCourses.Link") }}
                  </span>
                  <div class="add-link">
                    <el-input
                      type="text"
                      placeholder="https://Example.com"
                      v-model="FileInfo.url"
                    ></el-input>
                  </div>
                </el-tab-pane>
                <!-- Video -->
                <el-tab-pane label="video" name="video">
                  <span slot="label">
                    <i class="fab fa-youtube"></i>
                    Youtube Video
                  </span>
                  <div>
                    <div class="add-link mb-3">
                      <el-input
                        type="text"
                        placeholder="https://youtu.be/dQw4w9WgXcQ"
                        v-model="FileInfo.url"
                      ></el-input>
                    </div>
                    <img
                      style="width: 300px;"
                      src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240709193030.png"
                      alt=""
                    />
                  </div>
                </el-tab-pane>
                <!-- Test -->
                <el-tab-pane label="exam" name="exam">
                  <span slot="label">
                    <i class="fas fa-chart-bar"></i>
                    Test
                  </span>
                  <template>
                    <div class="mb-2">
                      <el-radio-group
                        v-model="NewFile.examType"
                        @change="getExams(NewFile.examType, 'Full Mock')"
                      >
                        <el-radio
                          v-for="exam in ExamTypes"
                          :key="exam"
                          :label="exam"
                          border
                        >
                          <b class="test-color">{{ exam }}</b>
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <div class="mb-2">
                      <el-radio-group
                        v-if="NewFile.examType"
                        v-model="NewFile.sectionType"
                        @change="
                          getExams(NewFile.examType, NewFile.sectionType)
                        "
                      >
                        <el-radio
                          v-for="section in SectionTypes"
                          :key="section.name"
                          :label="section.value"
                          border
                        >
                          {{ section.name }}
                        </el-radio>
                      </el-radio-group>
                    </div>
                    <div class="mb-2" v-if="practices">
                      <el-select
                        filterable
                        style="width:100%;margin-right:10px"
                        v-model="FileInfo.content_id"
                        placeholder="Select Test"
                      >
                        <el-option
                          v-for="(item, index) in practices"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                          :disabled="!item.can_purchase"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('newCourses.Folder Name')"
            prop="name"
          >
            <el-input
              type="text"
              :placeholder="$t('newCourses.Folder Name')"
              v-model="FileInfo.name"
              :maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('newCourses.Description')">
            <el-input
              type="textarea"
              :placeholder="$t('newCourses.Description')"
              v-model="FileInfo.description"
              :rows="6"
              :maxlength="500"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFileShow = false">
          {{ $t("newCourses.Cancel") }}
        </el-button>
        <el-button type="primary" @click="saveFileInfo">
          {{ $t("newCourses.Confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- <Heading heading="h4" :content="myTitle" class="mb-4"></Heading>
    <hr /> -->
    <el-card shadow="never">
      <div class="video-box">
        <div class="edu-play-left">
          <div class="section-card-list">
            <FilesItem
              :Files="Files"
              @createNewFile="createNewFile"
              @deleteFileBySlug="deleteFileBySlug"
              @viewFile="viewFile"
              @resetOrder="resetOrder"
            ></FilesItem>
            <div class="p-3">
              <el-button
                type="primary"
                size="default"
                style="width: 100%;"
                @click="createNewFile(null, null, Files)"
              >
                <i class="fa fa-plus"></i>
                New
              </el-button>
            </div>
          </div>
        </div>
        <div class="edu-play-right" v-if="file">
          <FileDetail :file="file" />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import JwtService from "@/common/jwt.service";
import Materials from "@/views/courses/materials/apis/materials";
import i18n from "@/i18n";
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import FilesItem from "@/views/courses/materials/components/Files";
import FileDetail from "@/views/courses/materials/components/FileDetail";
import formMixin from "@/mixins/form";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    FilesItem,
    FileDetail
  },

  mixins: [formMixin],

  props: ["classId"],
  data() {
    return {
      ExamTypes: ["SAT", "ACT"],
      NewFile: {
        examType: null,
        sectionType: null
      },
      Files: null,
      NowFile: null,
      practices: null,
      file: null,
      addFileShow: false,
      FileInfo: {
        newType: "folder",
        url: null,
        course_id: null,
        teaching_class_id: null,
        parent_id: null,
        type: "document",
        content_type: null,
        content_id: null,
        name: "",
        description: ""
      }
    };
  },
  computed: {
    headers() {
      return {
        Authorization: `Bearer ${JwtService.getToken()}`,
        "Accept-Language": i18n.locale,
        "X-Tenant-Slug": Theme.slug
      };
    },
    SectionTypes() {
      if (this.NewFile.examType) {
        if (this.NewFile.examType === "SAT") {
          return [
            {
              name: "Full Tests",
              value: "Full Mock"
            },
            {
              name: "Modules",
              value: "Module"
            },
            {
              name: "Drills",
              value: "Drill"
            }
          ];
        } else if (this.NewFile.examType === "ACT") {
          return [
            {
              name: "Full Tests",
              value: "Full Mock"
            }
          ];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    myTitle() {
      return this.$t("newCourses.Edit Materials");
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "files";
    },
    formRules() {
      const checkFile = (rule, value, callback) => {
        if (this.FileInfo.newType === "document") {
          if (this.FileInfo.type === "link" || this.FileInfo.type === "video") {
            if (!this.FileInfo.url) {
              callback(new Error("Material is required!"));
            } else {
              if (!this.checkUrl(this.FileInfo.url)) {
                callback(new Error("Link must be valid!"));
              } else {
                callback();
              }
            }
          } else {
            if (!this.FileInfo.content_id) {
              callback(new Error("Material is required!"));
            } else {
              callback();
            }
          }
        }
      };
      return {
        name: [
          {
            required: true,
            message: "Name is required!"
          }
        ],
        type: [{ validator: checkFile }]
      };
    }
  },
  watch: {},

  mounted() {
    this.getFiles();
  },

  methods: {
    async getExams(examType, sectionType) {
      this.practices = null;
      this.NewFile.sectionType = sectionType;
      const res = await Materials.getExams({
        exam_type_name: sectionType,
        test_name: examType,
        page: 1,
        keyword: this.NewFile.search,
        per_page: 99,
        sort_by: null,
        sort_order: null
      });
      this.practices = res.exams.data;
    },
    async getFiles() {
      const Files = await Materials.getFiles({
        teaching_class_id: this.$route.params.id,
        parent_id: 0
      });
      this.Files = Files;
    },
    createNewFile(id, File, Files) {
      console.log(Files);
      this.NowFile = null;
      this.NowFile = File;
      this.FileInfo = {
        newType: "folder",
        url: null,
        course_id: null,
        teaching_class_id: this.$route.params.id,
        parent_id: id,
        type: "document",
        content_type: null,
        content_id: null,
        name: " ",
        description: "",
        order: Files.length + 1
      };
      // this.$refs["form"].resetFields();
      this.addFileShow = true;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    async viewFile(file) {
      this.file = null;
      const res = await Materials.getFileDetail(file.id);
      this.file = res;
    },
    async saveFileInfo() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      if (this.FileInfo.newType == "folder") {
        this.FileInfo.type = "folder";
      } else {
        if (this.FileInfo.type === "document") {
          this.FileInfo.content_type = "file";
        }
        if (this.FileInfo.type === "link" || this.FileInfo.type === "video") {
          this.FileInfo.content_type = "url";
          // if (!this.checkUrl(this.FileInfo.url)) {
          //   return;
          // }
          const res = await Materials.uploadUrl({
            url: this.FileInfo.url
          });
          if (res) {
            this.FileInfo.content_id = res.id;
          }
        }
        if (this.FileInfo.type === "exam") {
          this.FileInfo.content_type = "exam";
        }
      }
      await Materials.addFile(this.FileInfo);
      this.addFileShow = false;
      if (this.NowFile && this.NowFile.id) {
        this.getFolderFiles(this.NowFile);
      } else {
        this.getFiles();
      }
      // this.getFiles();
    },
    deleteFileBySlug(File) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.deleteFileBySlug(File.id);
        this.getFiles();
      });
    },
    async getFolderFiles(File) {
      const files = await Materials.getFiles({
        teaching_class_id: this.$route.params.id,
        parent_id: File.id
      });
      this.$set(File, "files", files);
    },
    async resetOrder(Files) {
      console.log(Files);
      let arr = [];
      Files.forEach((file,index) => {
        arr.push({
          id: file.id,
          order:index+1
        });
      });
      const files = await Materials.resetOrder({
        items: arr
      });
    },
    checkUrl(link) {
      // var reg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;
      var reg = /^https?:\/\//;
      if (!reg.test(link)) {
        return false;
      } else {
        return true;
      }
    },
    // async resetOrder() {
    //   await Materials.resetOrder({ material_subjects: this.subjects });
    //   this.getSubjects();
    // },
    //添加文件
    async handleUploadSuccess(response) {
      this.loading = false;
      if (response) {
        this.FileInfo.content_id = response.id;
        this.FileInfo.name = response.original_name;
      }
    },
    beforeUpload(file) {
      this.loading = true;
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error(
          this.$t("newCourses.File cannot be bigger than 20 mb")
        );
        this.loading = false;
      }
      return isLt20M;
    },
    errorUpload() {
      this.loading = false;
    },
    overLimit() {
      this.$message.error("You can only upload one file at a time.");
    },
    // arr上下移动
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    upData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== 0) {
        newArr = this.swapItems(arr, index, index - 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    },
    downData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== arr.length - 1) {
        newArr = this.swapItems(arr, index, index + 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    },
    editSubject(index) {
      this.editSubjectDialog = true;
      this.subjectInfo = {
        name: this.subjects[index].name,
        isEdit: true,
        index: index
      };
    },
    removeSubjectAlert(subjects, index) {
      this.$confirm(
        this.$t(
          "newCourses.Do you want to delete this category AND all the materials in it?"
        ),
        {
          confirmButtonText: this.$t("newCourses.Delete BOTH"),
          cancelButtonText: this.$t("newCourses.Only delete the category"),
          type: "warning"
        }
      )
        .then(() => {
          this.removeSubjectData(subjects, index, "subject");
        })
        .catch(() => {
          this.removeData(subjects, index, "subject");
        });
    },
    removeSubjectData(arr, index, type) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await Materials.removeSubjectAll(arr[index].id);
        this.getSubjects();
      });
    },
    async removeData(arr, index, type) {
      this.$confirm(
        this.$t("newCourses.This will delete it forever, Continue? "),
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        if (type === "subject") {
          await Materials.removeSubject(arr[index].id);
        } else if (type === "material") {
          await Materials.removeMaterial(arr[index].id);
        } else if (type === "file") {
          arr.splice(index, 1);
        } else if (type === "practices") {
          arr.splice(index, 1);
        }
        this.getSubjects();
      });
    },
    // 重新排序文件和考试的顺序
    async resetMaterialsOrder(item) {
      await Materials.filesOrder(item.id, {
        material_files: item.material_files
      });
      this.getSubjects();
    },
    async saveSubject() {
      if (this.subjectInfo.isEdit) {
        await Materials.updateSubject(
          this.subjects[this.subjectInfo.index].id,
          { name: this.subjectInfo.name }
        );
      } else {
        await Materials.createSubject(this.classId, {
          name: this.subjectInfo.name
        });
      }
      this.getSubjects();
      this.editSubjectDialog = false;
    },
    createFile(subjectIndex) {
      this.fileForm = {
        isEdit: false,
        index: 0,
        title: "",
        slug: "",
        description: "",
        publish_at: moment(new Date()).format("YYYY-MM-DD")
      };
      this.selectedSubject = Number(this.activeSubject)
        ? Number(this.activeSubject)
        : 0;
      this.selectedSubject = subjectIndex;
      this.editHomeWorkDialog = true;
    },
    editFile(item, itemIndex, subjectIndex) {
      this.fileForm = {
        ...item,
        isEdit: true,
        index: itemIndex
      };
      this.selectedSubject = subjectIndex;
      this.editHomeWorkDialog = true;
    },
    async saveFile() {
      if (this.fileForm.isEdit) {
        await Materials.updateMaterial(
          this.subjects[this.selectedSubject].materials[this.fileForm.index].id,
          { ...this.fileForm }
        );
      } else {
        await Materials.createMaterial({
          material_subject_id: this.subjects[this.selectedSubject].id,
          ...this.fileForm
        });
      }
      this.getSubjects();
      this.editHomeWorkDialog = false;
    },
    addFile(item) {
      this.addFileShow = true;
      this.materialNow = item.id;
    }
  }
};
</script>

<style scoped>
.video-box {
  display: flex;
  /* margin-top: 1rem; */
}
.edu-play-left {
  min-width: 28%;
  max-width: 500px;
  border-right: 1px solid #ebeef5;
  /* max-height: 50rem;
    overflow: scroll; */
}
.section-card-list {
  height: 50rem;
  overflow: auto;
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
.video-box {
  display: flex;
  /* margin-top: 1rem; */
  background-color: #fafafa;
}
.edu-play-left {
  min-width: 28%;
  max-width: 50%;
  border-right: 1px solid #ebeef5;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* max-height: 50rem;
    overflow: scroll; */
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
.videoContainer {
  border: 1px solid #ebeef5;
  border-radius: 1rem;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  overflow: hidden;
}
.fileContainer {
  /* border: 1px solid #ccc; */
  /* border-radius: 1rem; */
  position: relative;
  /* padding-bottom: 56.25%; */
  padding: 1.5rem;
  overflow: auto;
  min-height: 600px;
  /* background-color: #fafafa; */
}

.videoContainer iframe,
.video-container object,
.video-container embed,
.videoContainer img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-card-list {
  height: 50rem;
  overflow: auto;
}
.section-season {
  position: relative;
  display: flex;
  /* margin-top: 0.5rem; */
  background: white;
  border: 0.5px solid transparent;
  border-radius: 6px;
  font-size: 1rem;
  overflow: visible;
  cursor: pointer;
  /* margin: 1rem; */
}
.season-order {
  width: 2.5rem;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: #9499a0;
  text-align: center;
}
.season-title {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #18191c;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 196px; */
}
.season-info {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #61666d;
  word-break: break-all !important;
}
.active {
  cursor: default;
  /* border: 1px solid var(--themeColor); */
  background: rgba(11, 163, 149, 0.05);
}
.section-season:hover {
  border: 1px solid var(--themeColor);
  background: rgba(11, 163, 149, 0.05);
}
.class-title {
  margin-top: 1rem;
}
.block-list-item-info {
  display: flex;
  width: 10rem;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2rem;
  color: #61666d;
}
.description {
  color: #9499a0;
  white-space: pre-line;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 100px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .video-box {
    display: block;
  }
  .section-card-list {
    min-height: auto;
  }
  .edu-play-right {
    padding-bottom: 0;
  }
  .fileContainer {
    padding: 1rem;
    padding-top: 1.5rem;
    min-height: auto;
  }
}
::v-deep .el-form-item__label {
  margin-bottom: 0 !important;
}
</style>
